




























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import InfiniteLoading from 'vue-infinite-loading'
import PBeatTableItem from '@elitepage/components/PBeatTableItem.vue'
import ListEmptyMessage from '~/components/ListEmptyMessage.vue'

@Component({ components: { PBeatTableItem, ListEmptyMessage, InfiniteLoading } })
export default class PBeatTable extends Vue {
    @Prop({ type: Array }) beatPlaylist: IBeat[]

    get showLoader() {
        return this.$route.name !== 'Home' && this.$route.name !== 'Producer'
    }

    infiniteHandler($state: any) {
        this.$emit('fetchMoreData', $state)
    }
}
